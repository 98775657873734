import React from 'react';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import useDocumentTitle from 'hooks/useDocumentTitle';

interface ISEO {
    className?: string;
    title?: string;
    description?: string;
    image?: string;
    noindex?: boolean;
    canonical?: string;
}

const SEO: React.FunctionComponent<ISEO> = (props) => {
    const {
        description = 'Playback is where communities of creators, fans and friends hang out and watch games together.',
        title = 'Watch live sports together',
        image,
        noindex,
        canonical,
    } = props;

    useDocumentTitle(title);

    return (
        <>
            <NextSeo
                title={title}
                description={description}
                openGraph={{
                    title: title,
                    description: description,
                    images: [{ url: image || 'https://www.getplayback.com/img/SocialCard.png' }],
                    type: 'website',
                }}
                twitter={{
                    site: '@watchplayback',
                    cardType: 'summary_large_image',
                }}
                noindex={noindex}
                canonical={canonical}
            />
            <Head>
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{
                        __html: `{
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "url": "https://www.getplayback.com",
                        "logo": "https://gcdn.getplayback.com/Social-Card.png"
                    }`,
                    }}
                />
            </Head>
        </>
    );
};

export default SEO;
